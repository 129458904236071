import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-trend-core';
import { ColorPickerModule } from 'ngx-color-picker';
import { WarningWordComponent } from './warning-word.component';
import { WarningWordEditComponent } from './warning-word-edit/warning-word-edit.component';

@NgModule({
  imports: [
    SharedModule,
    ColorPickerModule
  ],
  declarations: [
    WarningWordComponent,
    WarningWordEditComponent,
  ],
  exports: [
    WarningWordComponent,
    WarningWordEditComponent
  ]
})
export class WarningWordModule { }
