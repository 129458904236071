import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AbstractComponent, Channel, ConfirmationComponent, Pager, WarningWord, WarningWordService } from 'lib-trend-core';
import { Observable, Subject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { WarningWordEditComponent } from './warning-word-edit/warning-word-edit.component';

@Component({
  selector: 'warning-word-component',
  templateUrl: 'warning-word.component.html'
})
export class WarningWordComponent extends AbstractComponent implements OnInit {
  pager: Pager<WarningWord> = new Pager<WarningWord>({ perPage: 10 });
  listObservable: Observable<Pager<WarningWord>>;
  defaultValue: string = '';
  private termoDaBusca: Subject<string> = new Subject<string>();

  channel!: Channel;

  constructor(
    injector: Injector,
    public dialog: MatDialog,
    public warningWordService: WarningWordService,
  ) {
    super(injector);
    this.createForm();
  }

  ngOnInit(): void {
    this.getList();
  }

  private createForm(): void {
  }

  setupObservableSearch() {
    this.listObservable = this.termoDaBusca
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(term => {
        return this.warningWordService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams);
      }));
    this.listObservable.subscribe((pager: Pager<WarningWord>) => {
      this.pager = pager;
    });
  }

  search(termo: string) {
    this.pager.page = 1;
    this.searchString = termo;
    this.termoDaBusca.next(termo);
  }

  add(): void {
    const dialogRef = this.dialog.open(WarningWordEditComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getList();
    });
  }

  edit(item: WarningWord): void {
    const dialogRef = this.dialog.open(WarningWordEditComponent, {
      width: '600px',
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getList();
    });
  }

  delete(item: WarningWord): void {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) === true) {
        this.warningWordService.delete(item._id).subscribe({
          next: () => {
            this.alertService.success('Palavras de alerta excluídas com sucesso.');
            this.getList();
          },
          error: (err) => this.alertService.error(err.error.message)
        });
      }
    });
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getList();
  }

  private getList() {
    this.warningWordService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams).subscribe({
      next: (value) => {
        this.pager = value
        this.setupObservableSearch();
      },
      error: (err) => this.alertService.error(err.error.message),
    });
  }
}
