import { Component, Inject, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractComponent, Category, CategoryService, CategoryTypeEnum, Pager } from 'lib-trend-core';

@Component({
  selector: 'category-add.component.ts',
  templateUrl: './category-add.component.html',
  styleUrls: ['./category-add.component.scss']
})
export class CategoryAddComponent extends AbstractComponent {
  category: Category = {} as Category;
  pagerCategory: Pager<Category> = new Pager();
  listCategory: Array<Category> = new Array<Category>();

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<CategoryAddComponent>,
    private categoryService: CategoryService,
    @Inject(MAT_DIALOG_DATA) public data: Category
  ) {
    super(injector);

    this.category = {
      type: CategoryTypeEnum.RESPONSE,
    } as Category;

    this.createForm();
  }

  ngOnInit() {
    this.isNew = !this.data;

    if (!this.isNew) {
      this.category = this.data;
      this.formGroup.patchValue(this.data);
    }
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      name: [null, Validators.required],
    });
    this.formGroup.valueChanges.subscribe(value => {
      Object.assign(this.category, value);
    });
  }

  close(value?: any): void {
    this.dialogRef.close();
  }

  save() {
    if (!this.formGroup.valid) {
      this.validateAllFormFields(this.formGroup);
      return;
    }

    if (this.isNew) {
      let newCategory = { ...this.formGroup.value, company: this.getIDCurrentCompany() };

      this.categoryService.createCategory(newCategory).subscribe({
        next: (value) => {
          this.close(value)
          this.alertService.success('Categoria salva com sucesso!');
        },
        error: (err) => this.alertService.error(err.error),
      });
    } else {
      this.categoryService.update(this.category._id, this.category).subscribe({
        next: () => {
          this.close();
          this.alertService.success('Categoria atualizada com sucesso!');
        },
        error: (err) => this.alertService.error(err.error),
      });
    }
  }
}
