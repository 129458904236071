import { NgModule } from '@angular/core';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { SharedModule } from 'lib-trend-core';
import { CategoryModule } from '../category/category.module';
import { WarningWordModule } from '../warning-word/warning-word.module';
import { ResponseAutomaticComponent } from './response-automatic/response-automatic.component';
import { ResponseEditComponent } from './response-edit/response-edit.component';
import { ResponseComponent } from './response.component';

@NgModule({
  imports: [
    SharedModule,
    WarningWordModule,
    PickerModule,
    CategoryModule
  ],
  declarations: [
    ResponseComponent,
    ResponseEditComponent,
    ResponseAutomaticComponent,
  ],
  exports: [
    ResponseComponent,
    ResponseEditComponent,
    ResponseAutomaticComponent,
  ]
})
export class ResponseModule { }
