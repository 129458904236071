<div class="flex justify-center flex-col mb-3">
  <h1 class="font-black text-[20px] text-black">Configurações de Respostas Automáticas</h1>
  <h6 class="text-zinc-400 font-light text-sm my-2">Edite suas repostas automáticas abaixo</h6>
</div>
<div class="w-full h-full mt-4" *ngIf="pager.total > 0">
  <div class="w-full h-full flex flex-wrap gap-6">
    <div *ngFor="let item of pager.list; let i = index"
      class="bg-white p-4 shadow rounded-lg w-[30%] h-[320px] border-2 border-gray-100">
      <div class="w-full">
        <div class="flex justify-between">
          <label class="font-semibold flex gap-1 items-center text-black mb-[15px]">{{ item.header }}</label>
          <mat-slide-toggle color="#4213F6" [(ngModel)]="item.activated"></mat-slide-toggle>
        </div>

        <textarea class="w-full h-[200px] input-field" placeholder="Digite seu conteúdo..." style="resize: none;"
          [(ngModel)]="item.message" #textarea>
        </textarea>
        <div class="flex gap-2 w-full justify-end">
          <mat-icon (click)="applyFormat('bold', i, textarea)" class="cursor-pointer ml-2">format_bold</mat-icon>
          <mat-icon (click)="applyFormat('italic', i, textarea)" class="cursor-pointer ml-2">format_italic</mat-icon>
          <mat-icon (click)="applyFormat('underline', i, textarea)"
            class="cursor-pointer ml-2">format_underline</mat-icon>
          <div class="relative" id="emoji-container"></div>
          <mat-menu #menuVar="matMenu">
            <ng-container *ngFor="let variable of templateVariableList">
              <button mat-menu-item (click)="setVariableInContent(variable.value, i)">
                <mat-icon color="primary">{{ variable.icon }}</mat-icon>
                {{ variable.label }}
              </button>
            </ng-container>
          </mat-menu>

          <mat-icon class="cursor-pointer mr-2" (click)="toogleContainerEmoji(i)">sentiment_satisfied</mat-icon>
          <mat-icon [matMenuTriggerFor]="menuVar" class="cursor-pointer">code</mat-icon>
        </div>

        <!-- Emoji Picker Container -->
        <ng-container *ngIf="showContainerEmojiIndex === i">
          <emoji-mart (emojiSelect)="addEmoji($event, i)" [darkMode]="false"
            [ngClass]="getStyleContainerEmoji(i)"></emoji-mart>
        </ng-container>
      </div>
    </div>


  </div>

  <div class="flex flex-1 border-t-2 border-gray-200 justify-end items-center w-full gap-4 mt-3 mb-4">
    <button
      class="h-[45px] mt-3 px-14 flex justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none"
      (click)="updateAll()">Salvar</button>
  </div>
</div>
<empty-records-component *ngIf="pager.total == 0"
  [message]="'Nenhuma resposta automática gerada ainda para esse canal.'"
  [subMessage]="'Adicione um canal novo para gerar suas respostas automáticas.'">
</empty-records-component>