<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
  <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
  <path
    d="M28 24V28M28 32H28.01M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z"
    stroke="#039855" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />

</svg>
<div>
  <h1 class="font-black text-[20px] text-black mt-2.5">{{isNew? 'Salvar' : 'Atualizar'}} palavras de alerta</h1>
  <h6 class="text-zinc-400 font-light text-sm my-2">Preencha os dados e crie novas palvras de alerta</h6>
</div>

<form [formGroup]="formGroup" class="mt-4">
  <div id="row-form" class="row">
    <div class="flex flex-col mb-[15px]">
      <label class="text-black font-semibold">Canal para vincular esse alerta: *</label>
      <mat-select class="input-field" formControlName="channel" placeholder="Selecione">
        <div class="w-full h-[35px] p-2 mb-2">
          <input style="height: 35px !important;" class="input-field" type="text" (focus)="onFocusChannel()"
            [formControl]="filterControlChannel" placeholder="Pesquise por um canal...">
        </div>
        @for (item of listChannel; track item) {
        <mat-option [value]="item._id">{{item.name}}</mat-option>
        }
      </mat-select>
      <field-error-component [field]="'channel'" [label]="'Canal'" [formGroup]="formGroup">
      </field-error-component>
    </div>
    <div id="chip" class="col-md-12">
      <label for="" class="text-black font-semibold">Palavras-chave para identificar este alerta: *</label>
      <input class="input-field" placeholder="Nova palavra-chave" (keydown.enter)="add($event)" />
      <field-error-component [field]="'channel'" [label]="'Palavra chave'"
        [formGroup]="formGroup"></field-error-component>

      <div class="flex flex-wrap w-auto gap-1 mb-3">
        @for (item of keywords; track item) {
        <div class="mt-2 bg-[#bbc5d1] w-auto rounded-lg flex items-center h-9">
          <h1 class="text-white p-1">{{item}}</h1>
          <mat-icon class="cursor-pointer text-white" (click)="remove(item)">cancel</mat-icon>
        </div>
        }
      </div>
    </div>
    <div class="flex flex-col">
      <label class="text-black font-semibold">Personalize a categoria: *</label>
      <div class="flex gap-2">
        <div class="flex flex-col flex-1">
          <input class="input-field" maxlength="100" formControlName="title" placeholder="Titulo">
          <field-error-component [field]="'title'" [label]="'Titulo'" [formGroup]="formGroup">
          </field-error-component>
        </div>
        <div class="flex gap-1">
          <mat-form-field>
            <input matInput formControlName="bgColor" class="border-2 border-[#BDBDBD]">
          </mat-form-field>
          <div class="flex">
            <div class="flex justify-center items-center border-2 border-[#BDBDBD] rounded-lg w-[50px] h-[50px]">
              <input class="rounded-full w-[40px] h-[40px] border-none" [(colorPicker)]="color"
                [style.background]="color" (colorPickerSelect)="selectColor()" />
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</form>
<div class="flex flex-col">
  <label for="" class="text-black font-semibold">Suas palavras de alerta ficarão assim</label>
  <div class="mb-20">
    <span class="px-[10px] py-[5px] font-light text-white rounded-[5px]"
      [ngStyle]="{'background-color': formGroup.get('bgColor').value}">
      {{formGroup.get('title').value}}
    </span>
  </div>
  <div class="flex justify-between items-center w-full gap-2 mt-15">
    <button
      class="h-[45px] flex flex-1 justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100"
      (click)="close()">Cancelar</button>
    <button
      class="h-[45px] flex flex-1 justify-center items-center rounded-lg bg-[#1340f6] text-white font-normal hover:bg[#1340f6]"
      (click)="save()">{{isNew? 'Salvar' : 'Atualizar'}}</button>
  </div>
</div>