import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, Injector } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { AbstractComponent, Channel, ChannelService, WarningWord, WarningWordService } from 'lib-trend-core';
import { debounceTime, of, switchMap } from 'rxjs';

@Component({
  selector: 'warning-word-edit-component',
  templateUrl: './warning-word-edit.component.html',
})
export class WarningWordEditComponent extends AbstractComponent {
  filterControlChannel: FormControl = new FormControl('');

  warningWord!: WarningWord;
  channel!: Channel;
  color: string = '#A5A6F6';
  listChannel!: Array<Channel>;
  allChannel!: Array<Channel>;

  keywords: string[] = [];
  readonly separatorKeysCodes = [ENTER, COMMA] as const;


  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<WarningWordEditComponent>,
    public warningWordService: WarningWordService,
    @Inject(MAT_DIALOG_DATA) public data: WarningWord,
    private channelService: ChannelService,
  ) {
    super(injector);
    this.warningWord = {} as WarningWord;
    this.createForm();
    this.getListChannel();
    this.fetchFilterChannel();
  }

  ngOnInit() {
    this.isNew = (!!this.data ? false : true);

    if (!this.isNew) {
      this.warningWord = this.data
      this.formGroup.patchValue({ ...this.data, channel: this.data.channel._id });
      this.warningWord.channel = this.data.channel;
      this.keywords = this.warningWord.keyword;
      this.color = this.warningWord.bgColor;
    }
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      channel: [null, Validators.required],
      bgColor: [{ value: '#A5A6F6', disabled: true }, Validators.required],
      title: [null, Validators.required],
    });
    this.formGroup.valueChanges.subscribe(value => {
      Object.assign(this.warningWord, value);
    });
  }


  close(): void {
    this.dialogRef.close();
  }

  save() {
    if (!this.formGroup.valid) {
      this.validateAllFormFields(this.formGroup)
      return;
    }

    this.warningWord.bgColor = this.formGroup.get('bgColor').value;
    this.warningWord.keyword = this.keywords;

    if (this.isNew) {
      this.warningWordService.create(this.warningWord).subscribe({
        next: (value) => {
          this.dialogRef.close(value);
          this.alertService.success('Palavras de Alerta salvas com sucesso.');
        },
        error: (err) => {
          this.alertService.error(err.error.message);
        },
        complete: () => {
        },
      });
    } else {
      this.warningWordService.update(this.warningWord._id, this.warningWord).subscribe({
        next: (value) => {
          this.dialogRef.close(value);
          this.alertService.success('Palavras de Alerta salvas com sucesso.');
        },
        error: (err) => {
          this.alertService.error(err.error.message);
        },
        complete: () => {
        },
      });
    }
  }

  selectColor() {
    this.formGroup.get('bgColor').setValue(this.color);
  }

  add(event: any): void {
    const targetEl = event.target as HTMLInputElement;
    const value = (targetEl.value || '').trim();

    if (value) {
      this.keywords.push(value);
    }
    event.target.value = '';
  }

  remove(value: string): void {
    const index = this.keywords.indexOf(value);

    if (index >= 0) {
      this.keywords.splice(index, 1);

    }
  }

  onSelectChannel(event: MatSelectChange): void {
    this.formGroup.get('channel').setValue(event.source.value);
    this.channel = event.source.value;
  }

  private getListChannel(): void {
    this.channelService.getList().subscribe({
      next: (value) => {
        this.allChannel = value;
        this.listChannel = value;
      },
      error: err => this.alertService.error(''),
    });
  }

  private fetchFilterChannel(): void {
    this.filterControlChannel.valueChanges.pipe(
      debounceTime(300),
    ).subscribe((value: string) => {
      const currentValue = this.formGroup.get('channel')?.value || null;

      this.listChannel = this.allChannel.filter(channel =>
        channel.name.toLowerCase().includes(value.toLowerCase()) &&
        channel._id !== currentValue
      );

      const existingChannel = this.allChannel.find(channel => channel._id === currentValue);
      if (existingChannel) {
        this.listChannel.push(existingChannel);
      }
    });
  }

  onFocusChannel(): void {
    this.listChannel = this.allChannel;
  }
}
