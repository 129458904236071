<div class="flex flex-1 flex-col mb-[20px]">
  <div class="w-full flex items-center">
  </div>
  <div class="flex gap-2">
  </div>
  <div class="flex flex-col justify-start">
    <div>
      <h1 class="font-black text-[20px] text-black">Configuração do Chatbot</h1>
      <h6 class="text-zinc-400 font-light text-sm my-2">Preencha os dados para configurar o seu auto atendimento</h6>
    </div>
  </div>
  <div *ngIf="channel">
    <form [formGroup]="formGroup">
      <mat-slide-toggle formControlName="actived" class="example-slide" color="#4213F6">
        <span class="text-[#000] ml-2 text-[1rem]">
          {{formGroup.get('actived').value ? 'Desativar Chatbot' : 'Ativar Chatbot'}}</span>
      </mat-slide-toggle>
      <div class="w-full rounded-lg">
        <div class="main">
          <div class="content-msg flex flex-col h-full">
            <div class="flex items-center justify-center">
              <div class="w-full pb-3 flex justify-center items-center">
                <div class="input-container">
                  <div class="flex flex-col justify-end items-end">
                    <!-- <textarea class="w-full h-[150px] input-field" 
                              formControlName="message" 
                              placeholder="Digite seu conteúdo..." 
                              style="resize: none;">
                    </textarea> -->
                    <div class="textarea-container w-full">
                      <textarea #messageTextarea formControlName="message" class="input-textarea h-[80px]"
                        placeholder="Digite..." maxlength="320" (paste)="onPaste($event)"></textarea>

                      <div class="icon-container flex items-center">
                        <mat-icon (click)="applyFormat('bold')" class="cursor-pointer ml-2">format_bold</mat-icon>
                        <mat-icon (click)="applyFormat('italic')" class="cursor-pointer ml-2">format_italic</mat-icon>
                        <mat-icon (click)="applyFormat('underline')"
                          class="cursor-pointer ml-2">format_underline</mat-icon>
                        <mat-icon (click)="toogleContainerEmoji()"
                          class="cursor-pointer ml-2">sentiment_satisfied</mat-icon>
                        <div class="relative" id="emoji-container">
                          <ng-container *ngIf="showContainerEmoji">
                            <emoji-mart (emojiSelect)="addEmoji($event)" [i18n]="translator" [darkMode]="false"
                              class="absolute bottom-300 right-[40px] lg:bottom-100">
                            </emoji-mart>
                          </ng-container>
                        </div>
                        <mat-icon [matMenuTriggerFor]="menuVar" class="cursor-pointer">code</mat-icon>
                      </div>
                    </div>
                    <mat-menu #menuVar="matMenu">
                      <ng-container *ngFor="let variable of templateVariableList">
                        <button mat-menu-item (click)="setVariableInContent(variable.value)">
                          <mat-icon color="primary">{{ variable.icon }}</mat-icon>
                          {{ variable.label }}
                        </button>
                      </ng-container>
                    </mat-menu>
                  </div>
                </div>


              </div>
              <div class="flex justify-between items-center ">
                <div class="flex flex-col">
                  <div
                    class="img-selector bg-[#f7f7f7] border-2 border-gray-300 border-dashed rounded-lg flex flex-col cursor-pointer"
                    (click)="fileInputFile.click()">

                    <loading-component class="loading-chat" *ngIf="loadingSpinner$ | async"></loading-component>
                    <ng-container *ngIf="attachmentChatbot() else defaultView">
                      <img [src]="attachmentChatbot()" alt="Selected Media"
                        class="w-[100%] h-[80px] object-contain rounded-lg" />
                      <p class="mb-2 text-sm text-gray-500 dark:text-gray-400" *ngIf="!chatbot.attachment">
                        <span class="font-semibold text-[#4213F6] text-xl">Clique aqui para carregar</span>
                      </p>
                      <p class="text-xs text-gray-500 dark:text-gray-400" *ngIf="!chatbot.attachment">
                        PNG, JPG, JPEG ou PDF (MAX. 800x400px)
                      </p>
                    </ng-container>
                    <ng-template #defaultView>
                      <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                      </svg>
                    </ng-template>
                    <input #fileInputFile type="file" (change)="onMidiaChange($event)"
                      style="display: none; height: 200px;" />
                    <div class="mt-[10px]">
                      @if (chatbot.attachment) {
                      <button (click)="removeAttachment()" *ngIf="isAdmin()"
                        class="bg-red-600 text-white flex items-center rounded"><mat-icon>clear</mat-icon></button>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full">

          <div class="ml-[15px]">
            <div class="flex items-center">
              <span class="material-icons mr-2">menu</span>
              <h6 class=" font-black text-[15px] text-black">Menu do Bot</h6>
            </div>
            <small class="text-zinc-400">
              Configure as opções em que serão exibidas ao contato. O lead deverá digitar a opção desejada para ser
              direcionado
              a um departamento ou conteúdo que você determinar abaixo.
            </small>
            <div class="flex gap-4">
              <div class="flex flex-col">
                <div class="title-menu flex items-center justify-center">
                  <button (click)="addMenu()" *ngIf="isAdmin()">+ Adicionar ao menu principal</button>
                </div>
                <div class="main-menu-bot rounded-lg shadow-global">
                  <div class="menu w-full">
                    <div class="menu-list">
                      @if (chatbot.menus?.length > 0) {
                      <ul>
                        @for (menu of chatbot.menus; track menu; let index = $index) {
                        <li (click)="selectChatbotMenuOption(menu)">
                          {{ menu.option }} - {{ menu.title }}
                          <span (click)="removeMenu(index)">[x]</span>
                        </li>
                        }
                      </ul>
                      } @else {
                      <h6 class="text-zinc-400 font-light text-sm my-2 mt-4">Você não tem nenhum menu. Adicione um
                        acima!
                      </h6>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <ng-container formArrayName="menus">
                @for (menu of menus.controls; track menu; let index = $index) {
                @if (chatbotMenu && index === chatbotMenuIndex) {
                <div class="content h-[527  px] w-full border border-[#f7f7f7] rounded-lg  mt-[15px]"
                  [formGroupName]="index">

                  <div class="bg-[#f7f7f7] h-[50px] flex justify-between items-center pr-2 ">
                    <div class="  flex gap-2 ml-[15px]">
                      <h1 class="font-medium text-black"> {{ chatbotMenu.option }}</h1>
                      <h1> {{ chatbotMenu.title }}</h1>
                    </div>
                    <div>
                      <button (click)="removeMenu(index)">
                        <mat-icon class="ml-2" color="primary">delete</mat-icon>
                      </button>
                      <button (click)="activedChatBotMenu(index)">
                        <mat-icon class="ml-2" [color]="getMenuActivedValue(index) ? 'warn' : 'primary'">
                          {{ getMenuActivedValue(index) ? 'block' : 'check' }}
                        </mat-icon>
                      </button>
                    </div>
                  </div>

                  <div class="p-2 w-full h-full ">
                    <div class="flex w-full gap-2">
                      <div class="w-[15%]">
                        <label class="font-semibold text-black mt-[10px]">Opção
                        </label>
                        <input type="number" formControlName="option" class="input-field">
                      </div>
                      <div class="w-[85%]">
                        <label class="font-semibold text-black mt-[10px]">Título
                        </label>
                        <input type="text" formControlName="title" class="input-field">
                      </div>
                    </div>

                    <div class="flex ">
                      <div class="w-full h-full flex gap-6">
                        <div class="w-full h-full">
                          <div class="w-full">
                            <label class="font-semibold text-black mt-[15px]">Mensagem
                            </label>
                            <textarea formControlName="message" class="input-field" style="resize: none;"></textarea>
                          </div>
                          <div class="w-full flex flex-col justify-center items-center">
                            <div class="w-full">
                              <label class="font-semibold text-black mt-[10px]">Departamento
                              </label>
                              <mat-select class="input-field" formControlName="department"
                                (selectionChange)="onDepartmentChange($event.value, index)" placeholder="Selecione">
                                <div class="input-select-filter w-full p-2 mb-2">
                                  <input class="input-select-filter input-field" type="text"
                                    (focus)="onFocusDepartment()" [formControl]="getFilterControl(index, 'department')"
                                    (input)="fetchFilter(getFilterControl(index, 'department').value, index, 'department')" placeholder="Pesquise por um departamento...">
                                </div>
                                @for (item of allDepartments; track item) {
                                <mat-option [value]="item._id">{{item.name}}</mat-option>
                                }
                              </mat-select>
                            </div>
                            <div class="w-full mt-[10px]">
                              <label class="font-semibold text-black mt-[10px]">Atendente
                              </label>
                              <mat-select class="input-field" formControlName="user" placeholder="Selecione">
                                <div class="input-select-filter w-full p-2 mb-2">
                                  <input class="input-select-filter input-field" type="text" (focus)="onFocusUser()"
                                    [formControl]="getFilterControl(index, 'user')"
                                    (input)="fetchFilter(getFilterControl(index, 'user').value, index, 'user')" placeholder="Pesquise por um atendente...">
                                </div>
                                @for (item of allUsers; track item) {
                                <mat-option [value]="item._id">{{item.name}}</mat-option>
                                }
                              </mat-select>
                            </div>

                            <div class="w-full mt-[10px]">
                              <label class="font-semibold text-black mt-[10px]">Tags
                              </label>
                              <mat-select class="input-field" formControlName="tags" multiple placeholder="Selecione" [disableOptionCentering]="true">
                                <div class="input-select-filter w-full p-2 mb-2">
                                  <input class="input-select-filter input-field" type="text" (focus)="onFocusTags()"
                                    [formControl]="getFilterControl(index, 'tags')"
                                    (input)="fetchFilter(getFilterControl(index, 'tags').value, index, 'tags')" placeholder="Pesquise por tags...">
                                </div>
                                @for (item of allTags; track item) {
                                <mat-option [value]="item._id">{{item.title}}</mat-option>
                                }
                              </mat-select>
                            </div>
                          </div>
                        </div>
                        <div
                          class="w-[300px] bg-[#f7f7f7] rounded-lg flex flex-col justify-center items-center mt-[15px] cursor-pointer loading-container"
                          (click)="fileInputFileChatbotMenu.click()">
                          <loading-component class="loading"
                            *ngIf="loadingSpinnerChatbotMenu$ | async"></loading-component>
                          <ng-container *ngIf="attachmentChatbotMenu() else defaultView">
                            <img [src]="attachmentChatbotMenu()" alt="Selected Media"
                              class="w-[100%] h-[150px] object-contain rounded-lg" />
                            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"
                              *ngIf="!chatbot.menus[index]?.attachment">
                              <span class="font-semibold text-[#4213F6] text-[17px]">Clique aqui para carregar</span>
                            </p>
                            <p class="text-[11px] text-gray-500 dark:text-gray-400"
                              *ngIf="!chatbot.menus[index]?.attachment">
                              PNG, JPG, JPEG ou PDF (MAX. 800x400px)
                            </p>
                          </ng-container>
                          <ng-template #defaultView>
                            <svg width="57" height="56" viewBox="0 0 57 56" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                            </svg>
                          </ng-template>
                          <input #fileInputFileChatbotMenu type="file" (change)="onChangeFileChatbotMenu($event,index)"
                            style="display: none;" />
                          <div class="mt-[10px]">
                            @if (chatbotMenu.attachment) {
                            <button (click)="removeAttachmentChatbotMenu(index)"
                              class="bg-red-600 text-white flex items-center rounded"><mat-icon>clear</mat-icon></button>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                }
                }
              </ng-container>
            </div>
          </div>
          <!-- } -->
        </div>
        <div class="flex justify-end items-end gap-2">
          <div class="flex flex-1 border-t-2 border-gray-200 justify-end items-center w-full gap-4 mt-3 mb-4">
            <button
              class="h-[45px] mt-3 px-14 flex justify-center items-center rounded-lg  text-white font-normal   bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none"
              (click)="save()" *ngIf="isAdmin()">Salvar</button>
          </div>

        </div>
      </div>
    </form>
  </div>
</div>