import { NgModule } from '@angular/core';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { SharedModule } from 'lib-trend-core';
import { ChatbotInfoComponent } from './chatbot-info/chatbot-info.component';
import { ChatbotComponent } from './chatbot.component';
import { MenuAddComponent } from './menu-add/menu-add.component';

@NgModule({
  declarations: [
    ChatbotComponent,
    MenuAddComponent,
    ChatbotInfoComponent,
  ],
  imports: [
    SharedModule,
    PickerModule
  ],
  exports: [
    ChatbotComponent,
    MenuAddComponent,
    ChatbotInfoComponent,
  ],
  providers: [],
  schemas: []
})
export class ChatbotModule { }
