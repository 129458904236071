import { Component, Injector } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AbstractComponent } from 'lib-trend-core';


@Component({
  selector: 'chatbot-info',
  templateUrl: './chatbot-info.component.html',
  styleUrls: ['./chatbot-info.component.scss']
})
export class ChatbotInfoComponent extends AbstractComponent {


  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<ChatbotInfoComponent>,
  ) {
    super(injector);
  }

  ngOnInit() {
  }

  close(): void {
    this.dialogRef.close();
  }


}
