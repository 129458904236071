import { ChangeDetectorRef, Component, ElementRef, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractComponent, Channel, Chatbot, ChatbotService, HeaderTypeEnum, Pager, Response, ResponseService, ResponseTypeEnum } from 'lib-trend-core';
import { Observable } from 'rxjs';

@Component({
  selector: 'response-automatic-component',
  templateUrl: './response-automatic.component.html'
})
export class ResponseAutomaticComponent extends AbstractComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('messageTextarea') messageTextarea!: ElementRef<HTMLTextAreaElement>;
  @Input() channel: Channel;

  pager: Pager<any> = new Pager<any>({ perPage: 10 });
  listObservable: Observable<Pager<Response>>;

  showContainerEmojiIndex!: number | null;
  chatbot: Chatbot = <Chatbot>{};
  constructor(
    private chatbotService: ChatbotService,
    private cdRef: ChangeDetectorRef,
    injector: Injector,
    public responseService: ResponseService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes['channel']?.currentValue) {
      this.channel = changes['channel']?.currentValue;
      this.getList();
      this.getChatbot()
    }
  }

  override ngOnDestroy(): void {
  }

  private getList() {
    this.searchParams = {
      company: this.getIDCurrentCompany(),
      type: ResponseTypeEnum.AUTOMATIC,
      channel: this.channel._id,
    };

    this.responseService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams).subscribe({
      next: (value) => {
        this.pager = value;
      },
      error: (err) => this.alertService.error(err.error.message),
    });
  }

  private getChatbot(): void {
    this.chatbotService.getChatbotByCompanyAndChannel(this.getIDCurrentCompany(), this.channel._id).subscribe({
      next: (chatbot: Chatbot) => {
        this.chatbot = chatbot;
      },
      error: (err) => this.alertService.error(err.error.message)
    });
  }
  
  

  updateAll(): void {
    const allUpdates: any = this.pager.list.map(item => ({
      ...item,
      message: item.message,
      activated: item.activated,
    }));
  
    const isWelcomeActive = allUpdates.some(item => item.header === HeaderTypeEnum.WELCOME && item.activated === true);
    this.chatbotService.getChatbotByCompanyAndChannel(this.getIDCurrentCompany(), this.channel._id).subscribe({
      next: (chatbot: Chatbot) => {
        if (chatbot?.actived && isWelcomeActive) {
          this.alertService.info('Você não pode ativar a mensagem de boas-vindas pois o chatbot está ativo.');
          return;
        }
  
        this.responseService.updateAll(this.channel._id, allUpdates).subscribe({
          next: () => {
            this.alertService.success('Respostas automáticas atualizadas com sucesso.');
            this.cdRef.detectChanges();
          },
          error: (err) => {
            this.alertService.error('Ops! Ocorreu um erro ao tentar salvar suas configurações. Tente novamente mais tarde.');
          }
        });
      },
      error: (err) => {
        this.alertService.error(err.error.message || 'Erro ao verificar o status do chatbot.');
      }
    });
  }

  toogleContainerEmoji(index: number): void {
    if (this.showContainerEmojiIndex === index) {
      this.showContainerEmojiIndex = null;
    } else {
      this.showContainerEmojiIndex = index;
    }
  }

  addEmoji(event: any, index: number): void {
    this.pager.list[index].message += event.emoji.native;
  }

  getStyleContainerEmoji(index: number): string {
    switch (index) {
      case 0:
        return 'absolute bottom-75 left-[9.625rem] z-50';
      case 1:
        return 'absolute bottom-75 left-[39.5rem] z-50';
      case 2:
        return 'absolute bottom-75 left-[69.5rem] z-50';
      case 3:
        return 'absolute -bottom-70 left-[9.625rem] z-50';
      case 4:
        return 'absolute -bottom-70 left-[39.5rem] z-50'
      default:
        return 'absolute -bottom-70 left-[69.5rem] z-50';
    }
  }

  setVariableInContent(variable: string, index: number): void {
    this.pager.list[index].message = `${this.pager.list[index].message || ''} ${variable}`.trim();
  }

  applyFormat(format: string, index: number, textarea: HTMLTextAreaElement): void {
    const item = this.pager.list[index];
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const selectedText = item.message.substring(start, end);

    if (!selectedText) return;
    let formattedText = '';
    switch (format) {
      case 'bold':
        formattedText = `*${selectedText}*`;
        break;
      case 'italic':
        formattedText = `~${selectedText}~`;
        break;
      case 'underline':
        formattedText = `__${selectedText}__`;
        break;
      default:
        return;
    }
    const updatedMessage = item.message.substring(0, start) + formattedText + item.message.substring(end);
    item.message = updatedMessage;
    this.cdRef.detectChanges();
    textarea.setSelectionRange(start + formattedText.length, start + formattedText.length);
    textarea.focus();
  }

  updateMessage() {
    const textarea = this.messageTextarea.nativeElement as HTMLTextAreaElement;
    const messageWithoutLineBreaks = textarea.value;
    this.formGroup.patchValue({ message: messageWithoutLineBreaks });
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    let pastedData = clipboardData.getData('text/html') || clipboardData.getData('text/plain');

    pastedData = pastedData
      .replace(/<p[^>]*>/g, '\n')
      .replace(/<\/p>/g, '\n\n')
      .replace(/<li[^>]*>/g, '')
      .replace(/<\/li>/g, '\n')
      .replace(/<\/?html[^>]*>/g, '')
      .replace(/<\/?body[^>]*>/g, '')
      .replace(/<!--.*?-->/g, '')
      .replace(/<\/?br[^>]*>/g, '\n')
      .replace(/<\/?strong[^>]*>/g, '*')
      .replace(/<\/?b[^>]*>/g, '*')
      .replace(/<\/?em[^>]*>/g, '_')
      .replace(/<\/?i[^>]*>/g, '_')
      .replace(/<\/?u[^>]*>/g, '~$&~');

    pastedData = pastedData.replace(/\n{2,}/g, '\n\n').trim();

    const textarea = this.messageTextarea.nativeElement as HTMLTextAreaElement;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    textarea.value =
      textarea.value.substring(0, start) +
      pastedData +
      textarea.value.substring(end);

    textarea.setSelectionRange(start + pastedData.length, start + pastedData.length);
    textarea.focus();
    this.updateMessage();
  }

}
