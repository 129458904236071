import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-trend-core';
import { CategoryAddComponent } from './category-add/category-add.component';
import { CategoryComponent } from './category.component';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    CategoryComponent,
    CategoryAddComponent
  ],
  exports: [
    CategoryComponent,
    CategoryAddComponent
  ]
})
export class CategoryModule { }
