import { Component, Inject, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractComponent, Channel, Response } from 'lib-trend-core';
import { BehaviorSubject, Subject } from 'rxjs';


@Component({
  selector: 'menu-add-component',
  templateUrl: './menu-add.component.html',
  styleUrls: ['./menu-add.component.scss']
})
export class MenuAddComponent extends AbstractComponent {
  public formValueToSave: Subject<{ option: string, title: string }> = new Subject<{ option: string, title: string }>();

  private loadingSpinnerSubject = new BehaviorSubject<boolean>(false);
  loadingSpinner$ = this.loadingSpinnerSubject.asObservable();

  chatBotMenu: any = {} as any;

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<MenuAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
  ) {
    super(injector);
    this.createForm();
  }

  ngOnInit() {
    this.isNew = !this.data;
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      option: [null, Validators.required],
      title: [null, Validators.required],
    });
    this.formGroup.valueChanges.subscribe(value => {
      Object.assign(this.chatBotMenu, value);
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  save() {
    this.formValueToSave.next(this.formGroup.value);
    this.dialogRef.close();
  }
}
