<div class="w-full h-full">
  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="24" fill="#D1FADF" />
    <rect width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
    <path
      d="M14.9338 20.5H25.9338V22.5H14.9338V20.5ZM14.9338 18.5H25.9338V16.5H14.9338V18.5ZM14.9338 26.5H21.9338V24.5H14.9338V26.5ZM29.9438 23.37L30.6538 22.66C31.0438 22.27 31.6738 22.27 32.0638 22.66L32.7738 23.37C33.1638 23.76 33.1638 24.39 32.7738 24.78L32.0638 25.49L29.9438 23.37ZM29.2338 24.08L23.9338 29.38V31.5H26.0538L31.3538 26.2L29.2338 24.08Z"
      fill="#039855" />
  </svg>

  <h1 class="font-black text-[20px] text-black mt-2.5">{{isNew ? 'Adicionar' : 'Alterar'}} Menu</h1>
  <h6 class="text-zinc-400 font-light text-sm my-2">Preencha os dados e crie um novo menu</h6>

  <form [formGroup]="formGroup" class="w-full h-full flex gap-2">
    <div class="w-[20%]">
      <label class="font-semibold text-black">Opção</label>
      <input type="number" class="input-field" formControlName="option">
    </div>
    <div class="w-[80%]">
      <label class="font-semibold text-black">Título</label>
      <input type="text" class="input-field" formControlName="title">
    </div>
  </form>

  <div class="w-full flex gap-2">
    <button
      class="h-[45px] px-14 flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100 w-[47%]"
      (click)="close()">Fechar</button>
    <button
      class="h-[45px] px-14 flex justify-center items-center rounded-lg  text-white font-normal   bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none w-[47%]"
      (click)="save()">Adicionar</button>
  </div>
</div>